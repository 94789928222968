import Image from "next/image";
import { Layout, SEO, Section, Container } from "@/components";
import { AnchorButton } from "@narrative-software/narrative-web-ui";

const NotFound: React.FC = () => (
  <Layout>
    <SEO title="Page not found | Narrative" />

    <Section isFirstSection>
      <Container>
        <div className="text-center">
          <h1 className="text-h2 font-semibold leading-tight lg:text-h1">Aw dang, that’s a 404</h1>
          <p className="mt-6 md:mt-10 md:text-lg">
            We can’t seem to find the page you’re looking for. Please forgive us, friendo.
          </p>
        </div>
        <div className="flex justify-center mt-7 md:mt-10">
          <div className="w-full max-w-60">
            <AnchorButton colour="black" href="/">
              Go to home page
            </AnchorButton>
          </div>
        </div>
        <div className="flex justify-center mt-15">
          <Image src="/images/not-found@2x.png" alt="Page not found" width="188" height="334.5" priority />
        </div>
      </Container>
    </Section>
  </Layout>
);

export default NotFound;
